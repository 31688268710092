import { createRef, lazy } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";

// Mui
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

// Settings
import versionObj from "./version.json";

// Styles
import "./scss/App.scss";

// Custom components
import NavigationScroll from "./components/layout/NavigationScroll";
import Loadable from "./components/general/Loadable";
import themes from "./themes";

// Utils
import settings from "./settings.json";

// Hooks
import { useFetchUser } from "./hooks/useFetchUser";

// Interfaces
import { role } from "./interfaces/Base";

// Pages
const Home = Loadable(lazy(() => import("./components/pages/home/Home")));
const MainLayout = Loadable(lazy(() => import("./components/layout/MainLayout")));
const MinimalLayout = Loadable(lazy(() => import("./components/layout/MinimalLayout")));
const Login = Loadable(lazy(() => import("./components/pages/auth/Login")));
const User = Loadable(lazy(() => import("./components/pages/user/User")));
const Users = Loadable(lazy(() => import("./components/pages/users/Users")));
const Register = Loadable(lazy(() => import("./components/pages/auth/Register")));
const Logs = Loadable(lazy(() => import("./components/pages/logs/Logs")));
const ForgotPassword = Loadable(lazy(() => import("./components/pages/auth/ForgotPassword")));
const ChangePassword = Loadable(lazy(() => import("./components/pages/auth/ChangePassword")));
const Sites = Loadable(lazy(() => import("./components/pages/sites/Sites")));
const Site = Loadable(lazy(() => import("./components/pages/site/Site")));
const SiteConfig = Loadable(lazy(() => import("./components/pages/site/SiteConfig")));
const Clients = Loadable(lazy(() => import("./components/pages/clients/Clients")));
const Client = Loadable(lazy(() => import("./components/pages/client/Client")));
const ChargingStations = Loadable(lazy(() => import("./components/pages/chargingStations/ChargingStations")));
const ChargingStation = Loadable(lazy(() => import("./components/pages/chargingStation/ChargingStation")));
const Reports = Loadable(lazy(() => import("./components/pages/reports/Reports")));
const Alerts = Loadable(lazy(() => import("./components/pages/alerts/Alerts")));
const Cards = Loadable(lazy(() => import("./components/pages/cards/Cards")));
const Card = Loadable(lazy(() => import("./components/pages/cards/Card")));
const DebugLogs = Loadable(lazy(() => import("./components/pages/debugLogs/DebugLogs")));

const PrivateRoute = ({ title, rolesAllowed = [], children }: any) => {
    const { currentUser } = useFetchUser();

    document.title = `${title} - ${settings.app.title}`;

    // Hack-ish code to return you to the page you were before refresh
    if (!currentUser) {
        if (!window.location.href.includes("/login")) {
            return <Login />;
        }
    }

    return currentUser && rolesAllowed.some((r: string) => currentUser.roles.includes(r as role)) ? children : <Navigate to="/login" />;
};

const PublicRoute = ({ title, children }: any) => {
    document.title = `${title} - ${settings.app.title}`;
    return children;
};

const App: React.FC<any> = () => {
    console.log(`%cVersion: ${versionObj && versionObj.version}`, "color: #bada55");

    const snackbarRef: any = createRef();

    return (
        <StyledEngineProvider injectFirst>
            <SnackbarProvider
                className="snackbar__container"
                ref={snackbarRef}
                maxSnack={3}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                autoHideDuration={2000}
            >
                <ThemeProvider theme={themes()}>
                    <CssBaseline />
                    <NavigationScroll>
                        <BrowserRouter>
                            <Routes>
                                <Route
                                    path="/"
                                    element={
                                        <PrivateRoute title="Accueil" rolesAllowed={settings.app.roles}>
                                            <MainLayout>
                                                <Home />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />

                                <Route
                                    path="users"
                                    element={
                                        <PrivateRoute title="Utilisateurs" rolesAllowed={["superAdmin"]}>
                                            <MainLayout>
                                                <Users />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />

                                <Route path="user">
                                    <Route
                                        path=":id"
                                        element={
                                            <PrivateRoute title="Utilisateur" rolesAllowed={["superAdmin"]}>
                                                <MainLayout>
                                                    <User />
                                                </MainLayout>
                                            </PrivateRoute>
                                        }
                                    />
                                </Route>

                                <Route
                                    path="sites"
                                    element={
                                        <PrivateRoute title="Sites" rolesAllowed={settings.app.roles}>
                                            <MainLayout>
                                                <Sites />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />

                                <Route path="site">
                                    <Route
                                        path=":id"
                                        element={
                                            <PrivateRoute title="Site" rolesAllowed={settings.app.roles}>
                                                <MainLayout>
                                                    <Site />
                                                </MainLayout>
                                            </PrivateRoute>
                                        }
                                    />
                                </Route>

                                <Route path="siteconfig">
                                    <Route
                                        path=":id"
                                        element={
                                            <PrivateRoute title="Configuration de site" rolesAllowed={settings.app.roles}>
                                                <MainLayout>
                                                    <SiteConfig />
                                                </MainLayout>
                                            </PrivateRoute>
                                        }
                                    />
                                </Route>

                                <Route
                                    path="clients"
                                    element={
                                        <PrivateRoute title="Clients" rolesAllowed={["superAdmin", "clientAdmin"]}>
                                            <MainLayout>
                                                <Clients />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />

                                <Route path="client">
                                    <Route
                                        path=":id"
                                        element={
                                            <PrivateRoute title="Client" rolesAllowed={["superAdmin", "clientAdmin"]}>
                                                <MainLayout>
                                                    <Client />
                                                </MainLayout>
                                            </PrivateRoute>
                                        }
                                    />
                                </Route>

                                <Route
                                    path="chargingStations"
                                    element={
                                        <PrivateRoute title="Bornes de recharge" rolesAllowed={settings.app.roles}>
                                            <MainLayout>
                                                <ChargingStations />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />

                                <Route path="chargingStations">
                                    <Route
                                        path=":siteId"
                                        element={
                                            <PrivateRoute title="Bornes de recharges" rolesAllowed={settings.app.roles}>
                                                <MainLayout>
                                                    <ChargingStations />
                                                </MainLayout>
                                            </PrivateRoute>
                                        }
                                    />
                                </Route>

                                <Route path="chargingStation">
                                    <Route
                                        path=":id"
                                        element={
                                            <PrivateRoute title="Borne de recharge" rolesAllowed={settings.app.roles}>
                                                <MainLayout>
                                                    <ChargingStation />
                                                </MainLayout>
                                            </PrivateRoute>
                                        }
                                    />
                                </Route>

                                <Route
                                    path="reports"
                                    element={
                                        <PrivateRoute title="Journaux" rolesAllowed={["superAdmin"]}>
                                            <MainLayout>
                                                <Reports />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />

                                <Route
                                    path="logs"
                                    element={
                                        <PrivateRoute title="Journaux" rolesAllowed={["superAdmin"]}>
                                            <MainLayout>
                                                <Logs />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />

                                <Route
                                    path="debuglogs"
                                    element={
                                        <PrivateRoute title="Journaux" rolesAllowed={["superAdmin"]}>
                                            <MainLayout>
                                                <DebugLogs />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />

                                <Route
                                    path="login"
                                    element={
                                        <PublicRoute title="Connexion">
                                            <MinimalLayout>
                                                <Login />
                                            </MinimalLayout>
                                        </PublicRoute>
                                    }
                                />

                                <Route
                                    path="alerts"
                                    element={
                                        <PrivateRoute title="Alertes" rolesAllowed={["superAdmin"]}>
                                            <MainLayout>
                                                <Alerts />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />

                                <Route
                                    path="cards"
                                    element={
                                        <PrivateRoute title="Cartes" rolesAllowed={settings.app.roles}>
                                            <MainLayout>
                                                <Cards />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />

                                <Route path="card">
                                    <Route
                                        path=":id"
                                        element={
                                            <PrivateRoute title="Carte" rolesAllowed={settings.app.roles}>
                                                <MainLayout>
                                                    <Card />
                                                </MainLayout>
                                            </PrivateRoute>
                                        }
                                    />
                                </Route>

                                <Route path="card">
                                    <Route path=":id">
                                        <Route
                                            path=":siteId"
                                            element={
                                                <PrivateRoute title="Carte" rolesAllowed={settings.app.roles}>
                                                    <MainLayout>
                                                        <Card />
                                                    </MainLayout>
                                                </PrivateRoute>
                                            }
                                        />
                                    </Route>
                                </Route>

                                <Route
                                    path="register"
                                    element={
                                        <PublicRoute title="Inscription">
                                            <MinimalLayout>
                                                <Register />
                                            </MinimalLayout>
                                        </PublicRoute>
                                    }
                                />
                                <Route
                                    path="/forgotPassword"
                                    element={
                                        <PublicRoute title="Mot de passe oublié">
                                            <ForgotPassword />
                                        </PublicRoute>
                                    }
                                />
                                <Route
                                    path="/changePassword"
                                    element={
                                        <PublicRoute title="Changement de mot de passe">
                                            <ChangePassword />
                                        </PublicRoute>
                                    }
                                />
                                <Route path="*" element={<Login />} />
                            </Routes>
                        </BrowserRouter>
                    </NavigationScroll>
                </ThemeProvider>
            </SnackbarProvider>
        </StyledEngineProvider>
    );
};

export default App;
