import { useEffect, useState } from "react";
import { singletonHook } from "react-singleton-hook";

// Interfaces
import { User as IUser } from "../interfaces/User";

// Firebase
import { auth, firestore } from "../firebase/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { genDoc } from "../utils/firebase";
import { User } from "firebase/auth";

const initState = {
    currentUser: null,
    authUser: null,
    fetchingUser: true,
    loading: false,
    // eslint-disable-next-line
    setLoading: (value: any) => {
        //
    },
};

const useFetchUserImpl = () => {
    // States
    const [loading, setLoading] = useState(false);
    const [currentUser, setCurrentUser] = useState<IUser | null>(null);
    const [authUser, setAuthUser] = useState<User | null>(null);
    const [fetchingUser, setFetchingUser] = useState<boolean>(true);

    useEffect(() => {
        let unsub: any = null;

        auth.onAuthStateChanged(async res => {
            if (res) {
                setAuthUser(res);
                setFetchingUser(true);

                unsub = onSnapshot(doc(firestore, "Users", res.uid), payload => {
                    setCurrentUser(genDoc<IUser>()(payload));
                    setFetchingUser(false);
                });
            } else {
                setAuthUser(null);
                setCurrentUser(null);
                setFetchingUser(false);
            }
        });

        return () => {
            typeof unsub === "function" && unsub();
        };
    }, []);

    return { currentUser, authUser, fetchingUser, loading, setLoading };
};

export const useFetchUser = singletonHook(initState, useFetchUserImpl);
